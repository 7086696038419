export default {
  EQUIPMENT_LIST: "Equipment list",
  ADD_EQUIPMENT: "Add equipment",
  EQUIPMENT_ADDED: "Equipment added",
  EDIT_EQUIPMENT: "Update equipment",
  EQUIPMENT_UPDATED: "Equipment updated",
  DELETE_THIS_EQUIPMENT: "delete equipment ?",
  EQUIPMENT_DELETED: "Equipment deleted",
  EQUIPMENT_NAME: "Equipment name",
  VIEW_EQUIPMENT: "Equipment details",
  EQUIPMENT_CATEGORY_NAME: "Equipment category",
  SAME_EQUIPMENTS:
    'One or more equipment already exist with the name {name}. Is this the same equipment? Click "Continue" to save if so, otherwise click "Cancel."',
};
